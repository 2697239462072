import React from "react"
import { TextBodyProps, Text } from "@opensea/ui-kit"
import { isAfter } from "date-fns"
import { graphql, useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { IS_SERVER } from "@/constants/environment"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Block, BlockProps } from "@/design-system/Block"
import { Button, ButtonProps } from "@/design-system/Button"
import { MultiStepModal } from "@/design-system/Modal/MultiStepModal.react"
import { Tooltip } from "@/design-system/Tooltip"
import { useScheduledOrderText } from "@/hooks/useScheduledOrderText"
import { useTranslate } from "@/hooks/useTranslate"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"
import { QuickBuyButton_order$key } from "@/lib/graphql/__generated__/QuickBuyButton_order.graphql"
import { truncateAddress, addressesEqual } from "@/lib/helpers/address"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { QuickBuyModalContent } from "../QuickBuyModalContent"

export type QuickBuySource =
  | "itemCard"
  | "tradeStation"
  | "activity"
  | "listingsTable"

export type QuickBuyButtonProps = {
  order: QuickBuyButton_order$key
  overrides?: {
    Button?: {
      props?: Partial<ButtonProps>
    }
    Text?: {
      props?: Partial<TextBodyProps>
    }
  }
  size?: "small" | "large"
  variant?: "primary" | "secondary"
  children?: React.ReactNode
} & Omit<BlockProps, "order">

export const QuickBuyButton = ({
  order: orderDataKey,
  overrides,
  children,
  size = "large",
  variant = "primary",
  ...blockProps
}: QuickBuyButtonProps) => {
  const t = useTranslate("orders")

  const connectedAddress = useConnectedAddress()
  const { isActiveAccount } = useWallet()

  const trackClickQuickBuy = useTrackingFn("click quick buy")

  const order = useFragment(
    graphql`
      fragment QuickBuyButton_order on OrderV2Type {
        maker {
          address
        }
        taker {
          address
          # eslint-disable-next-line relay/must-colocate-fragment-spreads used via isActiveAccount
          ...wallet_accountKey
        }
        item {
          __typename
          chain {
            identifier
          }

          # eslint-disable-next-line relay/must-colocate-fragment-spreads we're using this
          ...itemEvents_dataV2
        }
        openedAt
        relayId
      }
    `,
    orderDataKey,
  )

  const isOwnOrder = addressesEqual(order.maker.address, connectedAddress)
  const canTake = !order.taker || isActiveAccount(order.taker)

  const listingTime = dateFromISO8601(order.openedAt)
  const isScheduledOrderInFuture = isAfter(listingTime, new Date())

  const scheduledOrderText = useScheduledOrderText(listingTime, "buy")

  let error = ""

  if (isOwnOrder) {
    error = t("quickBuy.errors.ownOrder", "You own this item.")
  } else if (!canTake) {
    error = order.taker.address
      ? t(
          "quickBuy.errors.reservedListing",
          "This listing is reserved for {{address}}.",
          { address: truncateAddress(order.taker.address) },
          { forceString: true },
        )
      : t("quickBuy.errors.privateListing", "Private listing")
  } else if (isScheduledOrderInFuture) {
    error = scheduledOrderText
  }

  const isCompact = size === "small"
  const ctaText = isCompact
    ? t("quickBuy.buy", "Buy")
    : t("quickBuy.buyNow", "Buy now")

  return (
    <MultiStepModal
      trigger={open => (
        <NetworkUnsupportedGate
          chainIdentifier={order.item.chain.identifier}
          shouldAuthenticate
        >
          {({ handleIfNotSupported }) => (
            <Tooltip
              appendTo={IS_SERVER ? undefined : document.body}
              content={error}
              disabled={!error}
              maxWidth={200}
            >
              <Block className="w-full" {...blockProps}>
                <StyledButton
                  $isCompact={isCompact}
                  aria-label={ctaText}
                  className="w-full"
                  disabled={Boolean(error)}
                  size={isCompact ? "small" : "large"}
                  variant={variant}
                  onClick={(
                    event:
                      | React.MouseEvent<HTMLButtonElement>
                      | React.MouseEvent<HTMLAnchorElement>,
                  ) => {
                    event.preventDefault()
                    event.stopPropagation()

                    handleIfNotSupported(() => {
                      open()
                      trackClickQuickBuy()
                    })()
                  }}
                  {...overrides?.Button?.props}
                  {...(isCompact
                    ? {
                        overrides: overrides?.Button?.props as
                          | ButtonProps
                          | undefined,
                      }
                    : undefined)}
                >
                  {children !== undefined ? (
                    children
                  ) : (
                    <Text.Body
                      color={variant === "primary" ? "white" : undefined}
                      size={isCompact ? "small" : "medium"}
                      weight="semibold"
                      {...overrides?.Text?.props}
                    >
                      {ctaText}
                    </Text.Body>
                  )}
                </StyledButton>
              </Block>
            </Tooltip>
          )}
        </NetworkUnsupportedGate>
      )}
    >
      {onClose => (
        <QuickBuyModalContent orderId={order.relayId} onClose={onClose} />
      )}
    </MultiStepModal>
  )
}

const StyledButton = styled(Button)<ButtonProps & { $isCompact: boolean }>`
  ${props =>
    props.$isCompact &&
    css`
      && {
        padding-bottom: 6px;
        padding-top: 6px;
      }
    `}
`
