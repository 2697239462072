import { graphql, useFragment } from "react-relay"
import { useIsQuickBuyEnabled_order$key } from "@/lib/graphql/__generated__/useIsQuickBuyEnabled_order.graphql"

export const useIsQuickBuyEnabled = (
  orderDataKey: useIsQuickBuyEnabled_order$key | null,
) => {
  const order = useFragment(
    graphql`
      fragment useIsQuickBuyEnabled_order on OrderV2Type {
        orderType
        item {
          __typename

          ... on AssetType {
            isCurrentlyFungible
          }
        }
      }
    `,
    orderDataKey,
  )

  if (
    !order ||
    order.orderType === "ENGLISH" ||
    order.item.__typename === "%other"
  ) {
    return false
  }

  // We only allow quick buy for ERC-721s
  return !order.item.isCurrentlyFungible
}
