/**
 * @generated SignedSource<<e9be00e275f069e18fd905d83ca0cd9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QuickBuyModalContentActionQuery$variables = {
  assets: ReadonlyArray<string>;
  paymentAsset: string;
  toAddress: string;
};
export type QuickBuyModalContentActionQuery$data = {
  readonly assetsTransferability: {
    readonly assetsWithReactivatableOrders: ReadonlyArray<{
      readonly relayId: string;
    }>;
  };
  readonly blockchain: {
    readonly balanceOf: {
      readonly unit: string;
    };
  };
};
export type QuickBuyModalContentActionQuery = {
  response: QuickBuyModalContentActionQuery$data;
  variables: QuickBuyModalContentActionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assets"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentAsset"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toAddress"
},
v3 = [
  {
    "kind": "Variable",
    "name": "assets",
    "variableName": "assets"
  },
  {
    "kind": "Variable",
    "name": "toAddress",
    "variableName": "toAddress"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "BlockchainType",
  "kind": "LinkedField",
  "name": "blockchain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "toAddress"
        },
        {
          "kind": "Variable",
          "name": "paymentAsset",
          "variableName": "paymentAsset"
        }
      ],
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "balanceOf",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuickBuyModalContentActionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AssetsTransferabilityType",
        "kind": "LinkedField",
        "name": "assetsTransferability",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetType",
            "kind": "LinkedField",
            "name": "assetsWithReactivatableOrders",
            "plural": true,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "QuickBuyModalContentActionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AssetsTransferabilityType",
        "kind": "LinkedField",
        "name": "assetsTransferability",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetType",
            "kind": "LinkedField",
            "name": "assetsWithReactivatableOrders",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "d79b6dc6fab1188d404ae8409f372e5a",
    "id": null,
    "metadata": {},
    "name": "QuickBuyModalContentActionQuery",
    "operationKind": "query",
    "text": "query QuickBuyModalContentActionQuery(\n  $assets: [AssetRelayID!]!\n  $toAddress: AddressScalar!\n  $paymentAsset: PaymentAssetRelayID!\n) {\n  assetsTransferability(assets: $assets, toAddress: $toAddress) {\n    assetsWithReactivatableOrders {\n      relayId\n      id\n    }\n  }\n  blockchain {\n    balanceOf(paymentAsset: $paymentAsset, identity: $toAddress) {\n      unit\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "75a69580199df901397758298e59847e";

export default node;
