import React, { useMemo } from "react"
import { filter } from "@/lib/helpers/array"
import { STRING_SEPARATOR } from "../constants"
import {
  TrackingContextProvider,
  useTrackingContext,
} from "../TrackingContext.react"
import { AnyPageTab, PageName } from "./PageTrackingContext/types"

type PageNameSource = PageName
type PageTabSource = AnyPageTab

type ComponentSource =
  | "CollectionAssetSearch"
  | "ItemCard"
  | "ItemTableRow"
  | "TradeStation"
  | "Listings"
  | "Offers"
  | "PortfolioTableRow"
  | "PortfolioTableExpandedRow"

/**
 * Pages and Components are proper case. Tabs are lower case.
 */
type SourceName = PageNameSource | PageTabSource | ComponentSource

type SourceContext = {
  final: SourceName | undefined
  sources: string
}

export type SourceTrackingContext = {
  Source?: SourceContext
}

type SourceTrackingContextProviderProps = {
  source: SourceName
  children: React.ReactNode
}

export const SourceTrackingContextProvider = ({
  source,
  children,
}: SourceTrackingContextProviderProps) => {
  const { getMergedTrackingContext: getCurrentTrackingContext } =
    useTrackingContext()

  const currentTrackingContext = getCurrentTrackingContext()
  const currentSources = currentTrackingContext.Source?.sources
  const pageName = currentTrackingContext.Page?.name
  const pageTab = currentTrackingContext.Page?.tab

  const properties: SourceContext = useMemo(() => {
    if (currentSources === undefined) {
      const sources: SourceName[] = filter([
        `${pageName}Page`,
        `${pageTab}Tab`,
        source,
      ])
      return {
        final: source,
        sources: sources.join(STRING_SEPARATOR),
      }
    }

    return {
      final: source,
      sources: [currentSources, source].join(STRING_SEPARATOR),
    }
  }, [currentSources, pageName, pageTab, source])

  return (
    <TrackingContextProvider name="Source" properties={properties}>
      {children}
    </TrackingContextProvider>
  )
}
