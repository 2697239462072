/* istanbul ignore file */
import React, { useRef } from "react"
import { IconProps, TextBodyProps, Text } from "@opensea/ui-kit"
import { isAfter } from "date-fns"
import { merge } from "lodash"
import { graphql, useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { IS_SERVER } from "@/constants/environment"
import {
  useWallet,
  useConnectedAddress,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Block, BlockProps } from "@/design-system/Block"
import {
  Button,
  ButtonProps,
  ButtonWithIconProps,
} from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { useScheduledOrderText } from "@/hooks/useScheduledOrderText"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { ItemAddToCartButton_order$key } from "@/lib/graphql/__generated__/ItemAddToCartButton_order.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { truncateAddress, addressesEqual } from "@/lib/helpers/address"
import { first } from "@/lib/helpers/array"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import {
  readShoppingCartOrder,
  useShoppingCartActions,
  useShoppingCartAddOrRemoveOrders,
  useShoppingCartOrders,
  useShoppingCartViewActions,
} from "../../utils/ShoppingCartContextProvider"

export type ItemAddToCartButtonProps = {
  order: ItemAddToCartButton_order$key
  overrides?: {
    Button?: {
      props?: Partial<ButtonProps>
    }
    Icon?: {
      props?: Partial<IconProps>
    }
    Text?: {
      props?: Partial<TextBodyProps>
    }
  }
  iconOnly?: boolean
  variant?: "default" | "compact"
} & Omit<BlockProps, "order">

const MAX_CART_LIMIT = 30

export const useItemAddToCart = ({
  order: orderDataKey,
}: {
  order: ItemAddToCartButton_order$key | null | undefined
}) => {
  const { showSuccessMessage } = useToasts()
  const t = useTranslate("bulkPurchase")
  const { addOrder, removeOrder } = useShoppingCartAddOrRemoveOrders()
  const orders = useShoppingCartOrders()
  const { isItemInCart, isOrderInCart } = useShoppingCartActions()
  const { open } = useShoppingCartViewActions()
  const connectedAddress = useConnectedAddress()
  const { isActiveAccount } = useWallet()

  const orderData = useFragment(
    graphql`
      fragment ItemAddToCartButton_order on OrderV2Type {
        maker {
          address
        }
        taker {
          address
        }
        item {
          __typename

          ... on AssetType {
            isCurrentlyFungible
          }

          # eslint-disable-next-line relay/must-colocate-fragment-spreads we're using this
          ...itemEvents_dataV2
        }
        openedAt
        ...ShoppingCartContextProvider_inline_order
      }
    `,
    orderDataKey ?? null,
  )

  const order = orderData ? readShoppingCartOrder(orderData) : null

  const isOrderAlreadyAdded = isOrderInCart(order?.relayId)

  const reachedMaxLimit = orders.length === MAX_CART_LIMIT

  const isOwnOrder = addressesEqual(orderData?.maker.address, connectedAddress)
  const canTake = !order?.taker || isActiveAccount(order.taker)

  const firstCartOrder = first(orders)

  const isConflictingChain =
    firstCartOrder &&
    firstCartOrder.item.chain.identifier !== order?.item.chain.identifier

  const assetIds = order?.item.assetQuantities
    ? getNodes(order.item.assetQuantities).map(a => a.asset.relayId)
    : order?.item.relayId
    ? [order.item.relayId]
    : []

  const listingTime = orderData?.openedAt
    ? dateFromISO8601(orderData.openedAt)
    : null
  const isScheduledOrderInFuture =
    listingTime != null ? isAfter(listingTime, new Date()) : false

  const scheduledOrderText = useScheduledOrderText(
    listingTime || new Date(),
    "buy",
  )

  let error = ""

  if (isOwnOrder) {
    error = t("addToCart.errors.ownOrder", "You own this item.")
  } else if (!canTake) {
    error = order.taker.address
      ? t(
          "addToCart.errors.reservedListing",
          "This listing is reserved for {{address}}.",
          { address: truncateAddress(order.taker.address) },
          { forceString: true },
        )
      : t("addToCart.errors.privateListing", "Private listing")
  } else if (
    !isOrderAlreadyAdded &&
    assetIds.some(isItemInCart) &&
    // Fungible items can be added to cart multiple times
    !orderData?.item.isCurrentlyFungible
  ) {
    error =
      orderData?.item.__typename === "AssetType"
        ? t(
            "addToCart.errors.assetInCart",
            "This item is already in your cart.",
          )
        : t(
            "addToCart.errors.bundleContainAsset",
            "This bundle contains an item already in your cart.",
          )
  } else if (reachedMaxLimit) {
    error = t(
      "addToCart.errors.maxLimit",
      "You can only add up to {{count}} items to your cart",
      { count: MAX_CART_LIMIT },
      { forceString: true },
    )
  } else if (isConflictingChain) {
    error = t(
      "addToCart.errors.conflictingChains",
      "Items from different chains can't be purchased together.",
    )
  } else if (isScheduledOrderInFuture) {
    error = scheduledOrderText
  }

  const onAddToCart = () => {
    if (isOrderAlreadyAdded) {
      if (order?.relayId) {
        removeOrder(order.relayId)
      }

      showSuccessMessage(t("addToCart.remove", "Removed from cart"), {
        onClick: open,
      })
    } else {
      if (order) {
        addOrder(order)
        showSuccessMessage(t("addToCart.success", "Added to cart"), {
          onClick: open,
        })
      }
    }
  }

  return { error, onAddToCart, isOrderAlreadyAdded, openCart: open }
}

export const ItemAddToCartButton = React.memo(function ItemAddToCartButton({
  order,
  overrides,
  variant = "default",
  iconOnly = false,
  ...blockProps
}: ItemAddToCartButtonProps) {
  const t = useTranslate("bulkPurchase")
  const buttonRef = useRef<HTMLButtonElement>(null)
  const { error, onAddToCart, isOrderAlreadyAdded } = useItemAddToCart({
    order,
  })

  const isCompact = variant === "compact"

  return (
    <Tooltip
      appendTo={IS_SERVER ? undefined : document.body}
      content={error}
      disabled={!error}
      maxWidth={200}
    >
      <Block className="w-full" {...blockProps}>
        <StyledButton
          $isCompact={isCompact}
          className="w-full"
          disabled={Boolean(error)}
          ref={buttonRef}
          size={isCompact ? "small" : "large"}
          variant={
            iconOnly && isCompact
              ? isOrderAlreadyAdded
                ? "secondary"
                : "primary"
              : "primary"
          }
          onClick={(
            event:
              | React.MouseEvent<HTMLButtonElement>
              | React.MouseEvent<HTMLAnchorElement>,
          ) => {
            event.preventDefault()
            event.stopPropagation()

            onAddToCart()
          }}
          {...(iconOnly
            ? {
                icon: isOrderAlreadyAdded
                  ? "remove_shopping_cart"
                  : "shopping_cart",
                overrides: merge(
                  {
                    Icon: { props: { marginRight: "4px", marginLeft: "4px" } },
                  },
                  (overrides?.Button?.props as ButtonWithIconProps | undefined)
                    ?.overrides,
                ),
              }
            : { icon: undefined })}
          {...overrides?.Button?.props}
        >
          {!iconOnly && (
            <Text.Body
              color="white"
              size="medium"
              weight="semibold"
              {...overrides?.Text?.props}
            >
              {isOrderAlreadyAdded
                ? t("addToCart.removeFromCart", "Remove from cart")
                : t("addToCart.addToCart", "Add to cart")}
            </Text.Body>
          )}
        </StyledButton>
      </Block>
    </Tooltip>
  )
})

const StyledButton = styled(Button)<
  {
    $isCompact: boolean
  } & ButtonProps
>`
  ${props =>
    props.$isCompact &&
    css`
      && {
        padding: 6px 8px 6px 8px;
      }
    `}
`
