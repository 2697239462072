/**
 * @generated SignedSource<<371fb08fdade32d426bb21dade0c8d6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuickBuyModalContentAction_moonpay$data = {
  readonly " $fragmentSpreads": FragmentRefs<"usePaymentMethod_moonpay">;
  readonly " $fragmentType": "QuickBuyModalContentAction_moonpay";
};
export type QuickBuyModalContentAction_moonpay$key = {
  readonly " $data"?: QuickBuyModalContentAction_moonpay$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuickBuyModalContentAction_moonpay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuickBuyModalContentAction_moonpay",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "listingId",
          "variableName": "orderId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "usePaymentMethod_moonpay"
    }
  ],
  "type": "MoonpayType",
  "abstractKey": null
};

(node as any).hash = "12e6c675f7476277b8561295d467dd8f";

export default node;
