/**
 * @generated SignedSource<<1c970097687e94d665dc280063179067>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useIsQuickBuyEnabled_order$data = {
  readonly item: {
    readonly __typename: "AssetType";
    readonly isCurrentlyFungible: boolean;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly orderType: OrderV2OrderType;
  readonly " $fragmentType": "useIsQuickBuyEnabled_order";
};
export type useIsQuickBuyEnabled_order$key = {
  readonly " $data"?: useIsQuickBuyEnabled_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsQuickBuyEnabled_order">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsQuickBuyEnabled_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCurrentlyFungible",
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "7a281ea3094e32f5dfc42735a0e2ece6";

export default node;
