/**
 * @generated SignedSource<<da201c149ecff9b61644a4c5d4d55729>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuickBuyModalContentQuery$variables = {
  orderId: string;
};
export type QuickBuyModalContentQuery$data = {
  readonly moonpay: {
    readonly " $fragmentSpreads": FragmentRefs<"QuickBuyModalContentAction_moonpay">;
  };
  readonly order: {
    readonly isOpen: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"QuickBuyModalContentAction_order">;
  };
};
export type QuickBuyModalContentQuery = {
  response: QuickBuyModalContentQuery$data;
  variables: QuickBuyModalContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "orderId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOpen",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuickBuyModalContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrderV2Type",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuickBuyModalContentAction_order"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MoonpayType",
        "kind": "LinkedField",
        "name": "moonpay",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "orderId",
                "variableName": "orderId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "QuickBuyModalContentAction_moonpay"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuickBuyModalContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrderV2Type",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "item",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChainType",
                "kind": "LinkedField",
                "name": "chain",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "identifier",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 30
                      }
                    ],
                    "concreteType": "AssetQuantityTypeConnection",
                    "kind": "LinkedField",
                    "name": "assetQuantities",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetQuantityTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetQuantityType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AssetType",
                                "kind": "LinkedField",
                                "name": "asset",
                                "plural": false,
                                "selections": (v5/*: any*/),
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "assetQuantities(first:30)"
                  }
                ],
                "type": "AssetBundleType",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PriceType",
            "kind": "LinkedField",
            "name": "perUnitPriceType",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "symbol",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "usd",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentAssetType",
            "kind": "LinkedField",
            "name": "payment",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orderType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemQuantityType",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MoonpayType",
        "kind": "LinkedField",
        "name": "moonpay",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "listingId",
                "variableName": "orderId"
              }
            ],
            "kind": "ScalarField",
            "name": "fiatCheckoutAvailability",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db14eb2c25eff6c326de1870ae35d563",
    "id": null,
    "metadata": {},
    "name": "QuickBuyModalContentQuery",
    "operationKind": "query",
    "text": "query QuickBuyModalContentQuery(\n  $orderId: OrderRelayID!\n) {\n  order(order: $orderId) {\n    isOpen\n    ...QuickBuyModalContentAction_order\n    id\n  }\n  moonpay {\n    ...QuickBuyModalContentAction_moonpay_ZqgzG\n  }\n}\n\nfragment QuickBuyModalContentAction_moonpay_ZqgzG on MoonpayType {\n  ...usePaymentMethod_moonpay_43XUCC\n}\n\nfragment QuickBuyModalContentAction_order on OrderV2Type {\n  relayId\n  item {\n    __typename\n    relayId\n    chain {\n      identifier\n    }\n    displayName\n    ... on AssetBundleType {\n      assetQuantities(first: 30) {\n        edges {\n          node {\n            asset {\n              relayId\n              id\n            }\n            id\n          }\n        }\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  perUnitPriceType {\n    unit\n    symbol\n    usd\n  }\n  payment {\n    relayId\n    id\n  }\n  ...usePaymentMethod_order\n}\n\nfragment useIsAvailableForBuyWithCard_data_43XUCC on MoonpayType {\n  fiatCheckoutAvailability(listingId: $orderId)\n}\n\nfragment usePaymentMethod_moonpay_43XUCC on MoonpayType {\n  ...useIsAvailableForBuyWithCard_data_43XUCC\n}\n\nfragment usePaymentMethod_order on OrderV2Type {\n  relayId\n  orderType\n  item {\n    __typename\n    relayId\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  itemQuantityType\n}\n"
  }
};
})();

(node as any).hash = "72f573e87e69137a74aa0c85b8ed6d0f";

export default node;
