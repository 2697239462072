import React, { useState } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import { BlockchainActionModalContent } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { FulfillActionModal } from "@/components/orders/FulfillActionModal.react"
import { InvalidOrderModalContent } from "@/components/trade/InvalidOrderModalContent.react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { MoonPayCheckoutModal } from "@/features/moonpay"
import { CoreMarketplaceActionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionContext.react"
import { QuickBuyModalContentAction_moonpay$key } from "@/lib/graphql/__generated__/QuickBuyModalContentAction_moonpay.graphql"
import { QuickBuyModalContentAction_order$key } from "@/lib/graphql/__generated__/QuickBuyModalContentAction_order.graphql"
import { QuickBuyModalContentActionQuery } from "@/lib/graphql/__generated__/QuickBuyModalContentActionQuery.graphql"
import {
  QuickBuyModalContentQuery,
  QuickBuyModalContentQuery$variables,
} from "@/lib/graphql/__generated__/QuickBuyModalContentQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { captureNoncriticalError } from "@/lib/sentry"
import { CheckoutDepositModal } from "../CheckoutModal/components/CheckoutDepositModal/CheckoutDepositModal.react"
import { usePaymentMethod } from "../CheckoutModal/usePaymentMethod"
import { InactiveListingWarningModalContent } from "../InactiveListingsWarning/InactiveListingsWarningModalContent.react"

type Props = QuickBuyModalContentQuery$variables & {
  onClose: () => unknown
}

const LazyQuickBuyModalContent = ({ orderId, onClose }: Props) => {
  const { order, moonpay } = useLazyLoadQuery<QuickBuyModalContentQuery>(
    graphql`
      query QuickBuyModalContentQuery($orderId: OrderRelayID!) {
        order(order: $orderId) {
          isOpen
          ...QuickBuyModalContentAction_order
        }
        moonpay {
          ...QuickBuyModalContentAction_moonpay @arguments(orderId: $orderId)
        }
      }
    `,
    {
      orderId,
    },
    {
      fetchPolicy: "network-only",
    },
  )

  if (!order.isOpen) {
    return <InvalidOrderModalContent action="Quick Buy" onClose={onClose} />
  }

  return (
    <SsrSuspense fallback={<BlockchainActionModalContent.Skeleton />}>
      <LazyQuickBuyModalContentAction
        moonpay={moonpay}
        order={order}
        onClose={onClose}
      />
    </SsrSuspense>
  )
}

const LazyQuickBuyModalContentAction = ({
  onClose,
  order: orderDataKey,
  moonpay: moonpayDataKey,
}: {
  order: QuickBuyModalContentAction_order$key
  moonpay: QuickBuyModalContentAction_moonpay$key
  onClose: () => unknown
}) => {
  const connectedAddress = useConnectedAddress()
  const [hasAcknowledged, setHasAcknowledged] = useState(false)

  const order = useFragment(
    graphql`
      fragment QuickBuyModalContentAction_order on OrderV2Type {
        relayId
        item {
          __typename
          relayId
          chain {
            identifier
          }
          displayName

          ... on AssetBundleType {
            assetQuantities(first: 30) {
              edges {
                node {
                  asset {
                    relayId
                  }
                }
              }
            }
          }
        }
        perUnitPriceType {
          unit
          symbol
          usd
        }
        payment {
          relayId
        }

        ...usePaymentMethod_order
      }
    `,
    orderDataKey,
  )

  const moonpay = useFragment(
    graphql`
      fragment QuickBuyModalContentAction_moonpay on MoonpayType
      @argumentDefinitions(orderId: { type: "OrderRelayID!" }) {
        ...usePaymentMethod_moonpay @arguments(listingId: $orderId)
      }
    `,
    moonpayDataKey,
  )

  const { enabled: isMoonpayCheckoutEnabled } = usePaymentMethod({
    order,
    moonpay,
  })

  const assetIds =
    order.item.__typename === "AssetType"
      ? [order.item.relayId]
      : order.item.__typename === "AssetBundleType"
      ? getNodes(order.item.assetQuantities).map(a => a.asset.relayId)
      : []

  const {
    assetsTransferability: { assetsWithReactivatableOrders },
    blockchain: {
      balanceOf: { unit: balance },
    },
  } = useLazyLoadQuery<QuickBuyModalContentActionQuery>(
    graphql`
      query QuickBuyModalContentActionQuery(
        $assets: [AssetRelayID!]!
        $toAddress: AddressScalar!
        $paymentAsset: PaymentAssetRelayID!
      ) {
        assetsTransferability(assets: $assets, toAddress: $toAddress) {
          assetsWithReactivatableOrders {
            relayId
          }
        }
        blockchain {
          balanceOf(paymentAsset: $paymentAsset, identity: $toAddress) {
            unit
          }
        }
      }
    `,
    {
      assets: assetIds,
      toAddress: connectedAddress ?? "",
      paymentAsset: order.payment.relayId,
    },
  )

  const renderFullActionModal = () => (
    <CoreMarketplaceActionTrackingContextProvider action="BuyNow">
      <FulfillActionModal
        assetIDs={assetIds}
        itemFillAmount="1"
        itemName={order.item.displayName}
        orderId={order.relayId}
        onClose={onClose}
      />
    </CoreMarketplaceActionTrackingContextProvider>
  )

  if (assetsWithReactivatableOrders.length > 0) {
    if (hasAcknowledged) {
      return renderFullActionModal()
    }

    return (
      <InactiveListingWarningModalContent
        numItems={1}
        numItemsWithInactiveListing={assetsWithReactivatableOrders.length}
        onContinue={() => setHasAcknowledged(true)}
      />
    )
  }

  if (bn(balance).isLessThanOrEqualTo(order.perUnitPriceType.unit)) {
    return isMoonpayCheckoutEnabled ? (
      <MoonPayCheckoutModal
        assetIDs={[order.item.relayId]}
        chainIdentifier={order.item.chain.identifier}
        order={order.relayId}
        onClose={onClose}
        onTransactionConfirmed={onClose}
      />
    ) : (
      <CheckoutDepositModal
        amount={bn(order.perUnitPriceType.usd)}
        chain={order.item.chain.identifier}
        symbol={order.perUnitPriceType.symbol}
        totalPrice={bn(order.perUnitPriceType.unit)}
        onFundsAdded={onClose}
      />
    )
  }

  return renderFullActionModal()
}

export const QuickBuyModalContent = (props: Props) => {
  return (
    <ErrorBoundary
      fallback={({ error }) => {
        captureNoncriticalError(error)
        return (
          <InvalidOrderModalContent
            action="Quick Buy"
            onClose={props.onClose}
          />
        )
      }}
    >
      <SsrSuspense fallback={<BlockchainActionModalContent.Skeleton />}>
        <LazyQuickBuyModalContent {...props} />
      </SsrSuspense>
    </ErrorBoundary>
  )
}
