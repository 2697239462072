import { format } from "date-fns"
import { DATETIME_FORMAT_STRING } from "@/constants/datetime"
import { useTranslate } from "./useTranslate"

export const useScheduledOrderText = (
  listingTime: Date,
  action: "buy" | "sell" | "bid on",
) => {
  const t = useTranslate("hooks")
  return t(
    "scheduledOrderText.template",
    "You can't {{action}} this item until {{listingTimeText}}",
    {
      // weird way to do this, but i18n extraction can't use variables
      action:
        action === "buy"
          ? t("scheduledOrderText.buy", "buy")
          : action === "sell"
          ? t("scheduledOrderText.sell", "sell")
          : t("scheduledOrderText.bidOn", "bid on"),
      // TODO(i18n): localize date
      listingTimeText: isNaN(listingTime.valueOf())
        ? ""
        : format(listingTime, DATETIME_FORMAT_STRING),
    },
    { forceString: true },
  )
}
